<template>
	<a-modal
		:visible="showVideoPop"
		title="Basic Modal"
		width="100%"
		wrapClassName="full-modal"
		:footer="null"
		@cancel="closePop">
		<video
			autoplay
			controls
			src="https://static.yangtao.com/static/home/gettingStarted.mp4"></video>
	</a-modal>
</template>

<script>
export default {
	name: "ComponentName",
	components: {},
	props: ["showVideoPop"],
	data() {
		return {};
	},
	methods: {
		closePop() {
			this.$emit("update:showVideoPop", false);
		},
	},
	created() {},
	mounted() {},
};
</script>
<style lang="less" scoped>
// ::v-deep .ant-modal-root {
::v-deep .ant-modal-wrap {
	.ant-modal {
		width: 100%;
		height: 100%;
		top: 0;
		padding: 0;
		.ant-modal-content {
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.45);
			backdrop-filter: blur(3px);
			display: flex;
			align-items: center;
			justify-content: center;
			.ant-modal-header {
				display: none;
			}
			.ant-modal-body {
				margin: 0 auto;
				padding: 0;
				video {
					width: 960px;
					height: 540px;
				}
			}
			.ant-modal-close {
				color: #fff;
				font-weight: bold;
			}
		}
	}
}
// }
</style>
