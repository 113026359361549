<template>
	<div class="manage_wrap">
		<div class="nav_wrap">
			<div class="nav_w_left">
				<div class="logo"></div>
				<div class="nav_middle">
					<div
						:class="index == check_nav ? 'nav_cell_now' : 'nav_cell_no'"
						v-for="(item, index) in nav_list"
						:key="item.value"
						@click="nav_click(index, item.menuName)">
						{{ item.name }}
					</div>
				</div>
			</div>

			<div class="nav_w_right">
				<div class="quick_tutorial" @click="showVideoPop = true">
					快捷教程
					<img src="../../assets/img/quick_tutorial_icon.png" alt="" />
				</div>
				<div class="divide_line"></div>
				<div class="rightcell_1" @mouseenter="servicePopupVisible = true" @mouseleave="servicePopupVisible = false">
					客服
					<transition name="popup">
						<div class="qr_popup" v-if="servicePopupVisible">
							<h2>客服</h2>
							<span>请扫描二维码</span>
							<img src="@/assets/images/service.jpg" alt="" />
							<span>或</span>
							<a-button @click="goService" type="primary">打开微信联系客服</a-button>
						</div>
					</transition>
				</div>
				<div class="divide_line"></div>
				<a-popover
					trigger="click"
					overlayClassName="manage-popover"
					@visibleChange="
						val => {
							dropDown(val, 2);
						}
					">
					<div slot="content" class="fast_pop_wrap">
						<div class="fast_pop_line" @click="$router.push('/manage/addenv')">新建环境</div>

						<div class="fast_pop_line" @click="$router.push('/manage/buyequipment')">购买设备</div>
					</div>

					<div class="rightcell_2" :class="{ active: rightcell_2_active }">便捷操作</div>
				</a-popover>
				<div class="divide_line"></div>
				<a-popover
					trigger="click"
					overlayClassName="manage-popover"
					@visibleChange="
						val => {
							dropDown(val, 3);
						}
					">
					<div slot="content" class="pop_wrap">
						<div class="pop_top">
							<div class="pop_t_left">
								<div class="pop_t_txt">环境数量</div>
								<div class="pop_t_num">{{ env_count }}</div>
							</div>
							<div class="pop_t_right">
								<div class="pop_t_txt">优惠券</div>
								<div class="pop_t_num">{{ coupon_count }}</div>
							</div>
						</div>

						<div class="pop_content">
							<div class="pop_c_left">
								<div class="pop_c_l_txt">账户余额</div>
								<div class="pop_c_l_num">¥ {{ balance ? balance : "0.00" }}</div>
							</div>
							<div class="pop_c_right">
								<div class="pop_btn" @click="go_page('charge_fee')">续费</div>
							</div>
						</div>

						<div class="pop_line">
							<div class="pop_line_ico"></div>
							<div class="pop_line_txt" @click="go_page('manage_edituser')">查看个人信息</div>
						</div>

						<div class="pop_line">
							<div class="pop_line_ico_2"></div>
							<div class="pop_line_txt" @click="go_page('invite_fee')">邀请有礼</div>
						</div>

						<div class="pop_line">
							<div class="pop_line_ico_3"></div>
							<div class="pop_line_txt" @click="go_out">退出登录</div>
						</div>
					</div>

					<div class="rightcell_3" :class="{ active: rightcell_3_active }">
						{{ username }}
					</div>
				</a-popover>

				<!-- <div class="rightcell_ico"></div> -->
			</div>
		</div>
		<router-view />
		<VideoPop :showVideoPop.sync="showVideoPop" />
	</div>
</template>

<script>
import { user_logout } from "@/api/login";
import VideoPop from "./components/VideoPop.vue";
export default {
	name: "manage_index",
	components: { VideoPop },
	data() {
		return {
			nav_list: [
				{
					name: "首页",
					value: "0",
					menuName: "manage_home",
				},
				{
					name: "环境管理",
					value: "1",
					menuName: "manage_environment",
				},
				{
					name: "设备管理",
					value: "2",
					menuName: "manage_equipment",
				},
				{
					name: "费用管理",
					value: "3",
					menuName: "manage_fee",
				},
				{
					name: "企业管理",
					value: "4",
					menuName: "manage_enterprise",
				},
				{
					name: "安全中心",
					value: "5",
					menuName: "manage_security",
				},
				{
					name: "API",
					value: "6",
					menuName: "ApiPage",
				},
				// {
				// 	name: "RPA",
				// 	value: "7",
				// 	menuName: "RPA",
				// },
			],

			member_list: [
				{
					name: "首页",
					value: "0",
					menuName: "manage_home",
				},
				{
					name: "环境管理",
					value: "1",
					menuName: "manage_environment",
				},
			],
			rightcell_2_active: false,
			rightcell_3_active: false,
			check_nav: 0,
			username: "",
			coupon_count: null,
			env_count: null,
			balance: null,
			// 权限 1:个人 2:企业
			permissions: "",
			user_role: "",
			showVideoPop: false,
			servicePopupVisible: false,
		};
	},
	watch: {
		$route: {
			handler: function (val, oldVal) {
				this.check_navstate();
			},
			// 深度观察监听
			deep: true,
		},
	},
	created() {
		let c_1 = JSON.parse(localStorage.member);
		this.username = c_1.real_name;
		this.env_count = c_1.env_count;
		this.coupon_count = c_1.coupon_count;
		this.balance = c_1.balance;
		this.user_role = c_1.user_role;
		if (c_1.user_role == 1) {
			this.nav_list = this.member_list;
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.check_navstate();
		});
	},
	methods: {
		//判断导航栏
		check_navstate() {
			if (this.$route.name == "manage_home") {
				this.check_nav = "0";
			}
			if (this.$route.name == "manage_environment" || this.$route.name == "manage_addenv") {
				this.check_nav = "1";
			}
			if (this.$route.name == "manage_equipment" || this.$route.name == "manage_renewal") {
				this.check_nav = "2";
			}
			if (this.$route.name.includes("manage_enterprise") || this.$route.name == "company_notac") {
				this.check_nav = "4";
			}
			if (this.$route.name == "member_security" || this.$route.name == "terminal_security") {
				this.check_nav = "5";
			}
			if (this.$route.name == "charge_fee" || this.$route.name == "invite_fee") {
				this.check_nav = "3";
			}
			if (this.$route.name == "ApiPage") {
				this.check_nav = "6";
			}
		},
		//下拉菜单激活
		dropDown(val, type) {
			if (type == 2) {
				this.rightcell_2_active = val;
			}
			if (type == 3) {
				this.rightcell_3_active = val;
			}
		},
		//跳转客服
		goService() {
			window.open("https://work.weixin.qq.com/kfid/kfce841fa55ddaa0789");
		},
		//菜单跳转
		nav_click(index, menuName) {
			//  return

			this.check_nav = index;
			this.$router.push({ name: menuName });
		},
		go_page(name) {
			this.$router.push({ name: name });
		},
		async go_out() {
			let { data } = await user_logout({
				token: localStorage.getItem("token"),
			});
			if (data.code == 200) {
				// localStorage.removeItem("user_password");
				localStorage.removeItem("member");
				// localStorage.removeItem("user_phone");
				localStorage.removeItem("user");
				localStorage.removeItem("token");
				this.$router.push({ name: "login" });
				location.reload();
			}
			if (data.code == 3) {
				localStorage.removeItem("user_password");
				localStorage.removeItem("member");
				localStorage.removeItem("user_phone");
				localStorage.removeItem("user");
				localStorage.removeItem("token");
				this.$router.push({ name: "login" });
				location.reload();
			}
		},
	},
};
</script>
<style lang="less">
.manage-popover {
	padding: 0 !important;

	.ant-popover-inner {
		.ant-popover-inner-content {
			padding: 0;
		}
	}
}
</style>
<style scoped lang="less">
.manage_wrap {
	background: #f0f2f5;
	margin-left: 40px;
	min-width: 1845px;
	min-height: 100vh;
	// padding-bottom: 20px;
	//overflow-x: auto;
	//border: 1px solid red;
	.nav_wrap {
		display: flex;
		justify-content: space-between;
		height: 60px;
		width: calc(100vw - 72px);
		margin: 0 auto;
		margin-left: 15px;
		margin-right: 15px;
		background-color: white;
		overflow: none;

		.nav_w_left {
			display: flex;

			.logo {
				flex: none;
				width: 202px;
				height: 28px;
				margin-left: 25px;
				margin-top: 15px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
				background-image: url("../../assets/img/logo_kyt.png");
			}

			.nav_middle {
				flex: none;
				display: flex;
				margin-left: 109px;

				.nav_cell_no {
					width: 90px;
					height: 100%;
					text-align: center;
					line-height: 60px;
					color: #374567;
					cursor: pointer;
					font-size: 14px;
				}

				.nav_cell_now {
					width: 90px;
					font-size: 14px;
					height: 100%;
					text-align: center;
					line-height: 60px;
					color: #3860f4;
					background: linear-gradient(0deg, rgba(76, 132, 255, 0.12), rgba(255, 255, 255, 0.12));
					border-bottom: 3px solid #4c84ff;
				}
			}
		}

		.nav_w_right {
			display: flex;
			align-items: center;
			margin-right: 10px;
			.divide_line {
				margin: 0 24px;
				width: 1px;
				height: 16px;
				background: #dedfe2;
			}
			.quick_tutorial {
				flex: none;
				width: fit-content;
				padding-left: 22px;
				height: 60px;
				font-size: 14px;
				color: #374567;
				line-height: 60px;
				background-repeat: no-repeat;
				background-position: 0px 21px;
				background-size: 18px 18px;
				background-image: url("../../assets/img/quick_tutorial.png");
				position: relative;
				cursor: pointer;
				&:hover {
					color: #4c84ff;
				}
				img {
					position: absolute;
					width: 59px;
					height: 16px;
					right: -40px;
					top: 6px;
				}
			}
			.rightcell_1 {
				flex: none;
				width: fit-content;
				padding-left: 22px;
				height: 60px;
				font-size: 14px;
				color: #374567;
				line-height: 60px;
				background-repeat: no-repeat;
				background-position: 0px 21px;
				background-size: 18px 18px;
				background-image: url("../../assets/img/客服@2x.png");
				cursor: pointer;
				position: relative;
				.qr_popup {
					position: absolute;
					width: 260px;
					padding: 20px;
					background-color: #fff;
					border-radius: 4px;
					z-index: 100;
					box-shadow: 0px 0px 24px 0px rgba(7, 5, 46, 0.1);
					left: -150px;
					display: flex;
					flex-direction: column;
					align-items: center;
					transition: all 0.3s;
					transform-origin: top center;
					h2 {
						font-size: 18px;
						font-weight: 600;
						line-height: 20px;
						margin: 0;
					}
					span {
						line-height: 20px;
						margin-top: 12px;
						color: #333;
					}
					.ant-btn {
						margin-top: 10px;
					}
					img {
						width: 100%;
						margin-top: 10px;
					}
				}
			}

			.rightcell_1:hover {
				color: #4c84ff;
			}

			.rightcell_line {
				width: 1px;
				height: 16px;
				margin-left: 23px;
				margin-right: 23px;
				margin-top: 24px;
				background: #dedfe2;
			}

			.rightcell_2 {
				width: fit-content;
				flex: none;
				padding-left: 22px;
				height: 60px;
				font-size: 14px;
				color: #374567;
				line-height: 60px;
				background-repeat: no-repeat;
				background-position: 0px 21px;
				background-size: 18px 18px;
				background-image: url("../../assets/img/便捷操作@2x.png");
				cursor: pointer;
				&::after {
					content: "";
					display: inline-block;
					width: 16px;
					height: 16px;
					margin-left: 5px;
					vertical-align: middle;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 100%;
					background-image: url("../../assets/img/下拉按钮@2x.png");
				}
				&.active::after {
					background-image: url("../../assets/img/上拉按钮@2x.png");
				}
			}

			.rightcell_2:hover {
				color: #4c84ff;
			}

			.rightcell_3 {
				width: fit-content;
				flex: none;
				padding-left: 22px;
				height: 60px;
				font-size: 14px;
				color: #374567;
				line-height: 60px;
				background-repeat: no-repeat;
				background-position: 0px 21px;
				background-size: 18px 18px;
				background-image: url("../../assets/img/团队@2x.png");
				cursor: pointer;
				&::after {
					content: "";
					display: inline-block;
					width: 16px;
					height: 16px;
					margin-left: 5px;
					vertical-align: middle;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 100%;
					background-image: url("../../assets/img/下拉按钮@2x.png");
				}
				&.active::after {
					background-image: url("../../assets/img/上拉按钮@2x.png");
				}
			}

			.rightcell_3:hover {
				color: #4c84ff;
			}

			.rightcell_ico {
				margin-left: 9px;
				margin-top: 28px;
				width: 9px;
				height: 5px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
				background-image: url("../../assets/img/下拉按钮@2x.png");
			}
		}
	}
}

.pop_wrap {
	width: 304px;
	height: 334px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	background-image: url("../../assets/img/用户信息背景@2x.png");

	padding: 10px;

	.pop_top {
		display: flex;

		// border: 1px solid red;
		.pop_t_left {
			width: 50%;
			text-align: center;
		}

		.pop_t_right {
			width: 50%;
			text-align: center;
		}

		.pop_t_txt {
			padding-top: 2px;
			color: #6a3e0a;
			font-size: 14px;
		}

		.pop_t_num {
			font-size: 22px;
			font-weight: bold;
			color: #6a3e0a;
		}
	}

	.pop_content {
		display: flex;

		// border: 1px solid green;
		.pop_c_left {
			width: 50%;

			.pop_c_l_txt {
				color: #e6d0c6;
				margin-top: 4px;
				margin-left: 35px;
			}

			.pop_c_l_num {
				height: 17px;
				margin-top: 9px;
				line-height: 17px;
				color: #efcfac;
				font-size: 14px;
				margin-left: 35px;
			}
		}

		.pop_c_right {
			width: 50%;

			.pop_btn {
				width: 64px;
				height: 22px;
				margin-left: 42px;
				margin-top: 17px;
				background: linear-gradient(90deg, #ffefd8, #fad7b0);
				border-radius: 2px;
				text-align: center;
				font-size: 14px;
				color: #6a3e0a;
				cursor: pointer;
			}
		}
	}

	.pop_line {
		display: flex;
		height: 18px;
		margin-top: 30px;
		margin-left: 25px;
		cursor: pointer;

		// border: 1px solid red;
		.pop_line_ico {
			width: 18px;
			height: 18px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			background-image: url("../../assets/img/个人信息 (2)@2x.png");
		}

		.pop_line_ico_2 {
			width: 18px;
			height: 18px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			background-image: url("../../assets/img/邀请@2x.png");
		}

		.pop_line_ico_3 {
			width: 18px;
			height: 18px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			background-image: url("../../assets/img/退出登录@2x.png");
		}

		.pop_line_txt {
			margin-left: 10px;
			line-height: 18px;
		}

		.pop_line_txt:hover {
			color: #4c84ff;
		}
	}
}
.fast_pop_wrap {
	width: 100px;
	padding: 8px;
	background-color: #fff;
	.fast_pop_line {
		width: 84px;
		height: 40px;
		margin: 0 auto;
		cursor: pointer;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #374567;
		line-height: 40px;
		text-align: center;
		&:hover {
			background-color: #f5f5f5;
			color: #4c84ff;
		}
	}
}
.popup-enter-active,
.popup-leave-active {
	transform: translateY(0) scale(1, 1);
}
.popup-enter,
.popup-leave-active {
	opacity: 0;
	transform: translateY(-20px) scale(1, 0);
	pointer-events: none;
}
</style>
